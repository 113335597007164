// stylelint-disable no-empty-source

$brand-1: red;
$brand-2: red;

$color-success: rgba(172, 216, 170, 1);
$color-error: rgba(255, 141, 141, 1);
$color-inactive-dark: rgba(155, 155, 155, 1);

$btn-border-radius: 5;
