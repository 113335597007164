// stylelint-disable no-empty-source

@import "_main";

// Gulp looks for variables in the following files in the specified order.
// 1. In the NodeJS package X-Net Rocket SCSS _variables.scss
// 2. In the project.json in the project root
// 3. In the custom_variables.scss
@import "_custom_variables";

//------------------------------------------------------------------------------
// ICONS
// stylelint-disable declaration-no-important
.icon-danger {
  fill: $color-error !important;
}

.icon-success {
  fill: $color-success !important;
}

// stylelint-enable declaration-no-important
//------------------------------------------------------------------------------
// LOGIN-AS
.fixed_alert_container {
  background-image: linear-gradient(to bottom, #FCF8E3 0%, #F8EFC0 100%);
  background-repeat: repeat-x;
  border-color: #F5E79E;
  font-size: rem(15);
  padding: rem(9);
}

.svg-inline {
  height: 1.25em;
  vertical-align: -0.225em;
  width: 1.25em;
}

//------------------------------------------------------------------------------
// RMA
[data-page-info-top="RMA_table"] {
  margin-left: 12rem;
}

.border-dotted {
  border-bottom: #666 rem(2) dotted;
}

.add-rma {
  form {
    margin-left: auto;
    margin-right: auto;
    max-width: 60rem;
  }
}

.add-rma-device {
  // stylelint-disable declaration-no-important
  .select2-container {
    .select2-selection--single {
      height: 2.4rem !important;
    }
  }

  #select2-id_addrmaform-device-container {
    line-height: 2.4rem;
  }

  .select2-selection__arrow {
    height: 2.4rem !important;
  }

  // stylelint-enable declaration-no-important
}

.show-appendix {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;

  img {
    width: 7rem;
  }

  .delete-appendix {
    height: rem(20);
    width: rem(20);
    cursor: pointer;
  }

  .delete-appendix-undo {
    height: rem(50);
    width: rem(50);
    cursor: pointer;
    margin-left: rem(55);
    margin-right: rem(55);
  }

  .fill-danger {
    fill: $danger;
  }

  .fill-inactive {
    fill: $gray-300;
  }
}

//------------------------------------------------------------------------------
// DASHBOARDS
.manufacturer-log-height {
  height: calc((100vh - 22rem) / 2);
}

.support-unit-log-height,
.distributor-log-height {
  height: calc(100vh - 16rem);
}

//------------------------------------------------------------------------------
// TOP BTN
#top-btn {
  display: none;
  position: fixed;
  bottom: rem(30);
  right: rem(30);
  z-index: 99;
}

.dataTables_wrapper {
  margin-bottom: 4rem;
}

#RMA_table_wrapper {
  margin-bottom: 1rem;
}

//------------------------------------------------------------------------------
// LICENSE
.license-card {
  max-width: rem(800);
}

.license-card-body {
  border-left: rem(1) solid $color-inactive-dark;
  border-right: rem(1) solid $color-inactive-dark;
  border-bottom: rem(1) solid $color-inactive-dark;
  border-bottom-right-radius: rem($btn-border-radius);
  border-bottom-left-radius: rem($btn-border-radius);

  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0.5rem;

  table {
    thead {
      border-bottom: rem(1) solid $color-inactive-dark;

      tr {
        th {
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        td {
          text-align: center;
        }
      }
    }
  }
}

.license-icon {
  height: rem(20);
  width: rem(20);
}

.license-valid {
  min-width: 30%;
  width: 30%;
  white-space: nowrap;
}

.license-variants {
  min-width: 25%;
  width: 25%;
  overflow: hidden;
  white-space: nowrap;
}

.license-variants-td {
  width: 13rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.license-media {
  min-width: 15%;
  width: 15%;
  white-space: nowrap;
}

.license-read {
  min-width: 15%;
  width: 15%;
  white-space: nowrap;
}

.license-card-header {
  background-color: #247AA5;
  color: white;
  padding: 0.5rem;
  padding-left: 1rem;
  border-left: rem(1) solid $color-inactive-dark;
  border-right: rem(1) solid $color-inactive-dark;
  border-top: rem(1) solid $color-inactive-dark;
  border-top-right-radius: rem($btn-border-radius);
  border-top-left-radius: rem($btn-border-radius);
  display: flex;
  justify-content: space-between;
}

.license-card-header-inactive {
  @extend .license-card-header;
  background-color: $color-inactive-dark;
}

.report-filter {
  display: flex;
  align-items: center;
  margin-right: 2rem;

  label {
    white-space: nowrap;
  }

  select {
    min-width: 12rem;
    width: 12rem;
  }
}

.license-report {
  details {
    summary {
      cursor: pointer;
      list-style: none;
      font-size: 160%;
      font-weight: 300;
    }

    summary::-webkit-details-marker {
      display: none;
    }

    summary::before {
      content: "▶️ ";
    }
  }

  details[open] > summary::before {
    content: "🔽 ";
  }

  .report-year {
    max-width: 56rem;
  }

  .report-month {
    max-width: 54rem;
  }

  .report-device {
    max-width: 52rem;
  }
}

//------------------------------------------------------------------------------
// CONTINGENT
.add-contingent {
  input {
    text-align: right;
  }
}

.color-green {
  color: green;
}

//------------------------------------------------------------------------------
// ORDER
.order-type {
  min-width: 10rem;
}

.text-end {
  text-align: end;
}
